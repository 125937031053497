
import { Action, Getter } from "vuex-class";
import { Component, Ref, Vue } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";
import { formatErrors } from "@/utils/formatters";
import { bus } from "@/main";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormError,
        FormSubmit,
    },
})
export default class DeleteTaskModal extends Vue {
    @Action("task/delete") deleteTask!: (id: number) => void;

    @Ref() form!: FormClass;

    payload = {};

    show = false;

    task_id: number | null = null;

    callback?: ((task_id: number) => void) | null = null;

    errorResponse: ErrorResponse = {
        status: 0,
        errors: [],
    };

    mounted() {
        bus.$off("show-delete-task");
        bus.$on("show-delete-task", (task_id: number, callback?: () => void) => {
            this.show = true;
            this.task_id = task_id;
            this.callback = callback;
        });
    }

    async submit(form: FormClass) {
        try {
            if (!this.task_id) {
                return;
            }

            await this.deleteTask(this.task_id);

            if (this.callback && typeof this.callback === "function") {
                this.callback(this.task_id);
            }

            this.$toast.open({ message: this.$t("views.task.deleted") as string, type: "success", position: "bottom-right" });

            this.handleClose();
        } catch (e) {
            this.errorResponse = formatErrors(e);
        } finally {
            form.reset();
        }
    }

    handleClose() {
        this.errorResponse = {
            status: 0,
            errors: [],
        };

        this.show = false;
    }
}
